.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 48.45%; /* Proporção de 16:9 para vídeos responsivos */
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.video-container img {
  position:absolute;
  top: 80%;
  left: 50%;
  animation: upAndDown 1s infinite alternate;
}

@keyframes upAndDown {
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-20px);
}
}


.main-banner div h1 {
  
position: absolute;
top: 40%;
left: 103px;
font-size: 2.5rem;
font-weight: 900;
max-width: 30%;
color:#FFFF;
font-family: 'Maison Neue Light';
}
.banner-text span div { 
position: absolute;
color:#FFFF;
top: 60%;
left: 103px;
font-size: 2rem;
max-width: 35%;

}
.muted-button {
  position: absolute;
  z-index: 97;
  background-color: tomato;
  left: 90%!important;
  top:5%;
  
}
.no-muted-image {
  width: 41px;
  height: 41px;
  animation: none!important;
}


@media (max-width: 768px) {

.scroll-button {
  display: none;
}

.muted-button {
  left: 80%;
  top:10%;
}

.no-muted-image {
  width: 25px;
  height: 25px;
}

}

@media (max-width: 950px) {
.banner-text span div { 
  top: 50%;
  font-size: 1.5rem;
}

.main-banner div h1 {
top: 30%;
font-size: 1.5em;
}

}

@media (max-width: 430px) {
.banner-text span div { 
    top: 70%;
    left: 30px;
    
    font-size: 0.8em;
}

.main-banner div h1 {
   top: 30%; 
   left: 30px;
   font-size: 1em;
   max-width: 100%;
}


}