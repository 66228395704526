.contact { 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin: 15rem 0;

}

.contactform {
    padding: 40px;
}

.contactform h3 {
    font-size: 3rem;
    margin: 3rem 0;
    font-family: 'Maison Neue Bold';
}

.contactform label {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
    font-family: 'Maison Neue Bold';
}
.contactform input,
.contactform textarea
 {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
    border: none;
    border-radius: 5px;
    /* border-bottom: 1px solid black; */
    box-shadow: 0px 10px 010px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.contactform textarea {
    padding-bottom: 5rem;
}

.contactform select {
    appearance: none;
    
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'><path d='M2 5l4 4 4-4H2z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    padding: 1rem ;
    width: 100%;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 10px 010px rgba(0, 0, 0, 0.2);

  }

.contactform button {
margin-top: 3rem;
display: flex ;
padding: 2rem 4rem;
background-color: black;
font-weight: 800;
color: white;
border: none;
border-radius: 50px;
cursor: pointer;
transition: 1s linear;
}

.submit  {
    margin: 0;
    padding: 0;
}

.contactform button:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transition: 1s linear;
}

.contact-info {
    display:flex;
    align-items: center;
    justify-content: center;

}
.info-contact-container {
    list-style: none;
    background-color: black;
    color: white;
    padding: 40px;
    border-radius: 100px;
    max-width:90%;
    display: flex;
    z-index: 3;
    margin-right: -3.5rem ;
}



.info-contact-container li{
    display: flex;
    list-style: none;
    padding: 1.5rem;
    margin-right: 3.5rem ;
    align-items: flex-start;
    max-width: 100%;
}

.info-contact-container p,
.info-contact-container h5{
    display: flex;
    list-style: none;
    margin: 10px 0 10px 30px;
    max-width: 100%;
    overflow-x:visible
}
.yellow-side-container{
    position:relative;
    background-color: var(--yellow-color);
    top:0%;
    right: 0%;
    height: 100%;
    width: 50%;
    border-radius: 200px 0 0 200px;
    display: flex;
    z-index: 2;

}

.yellow-side-bar {
    
}

.contact-back-content {
    padding: 3rem ;
    flex-direction: column;
    justify-content: center;
    
}

.contact-back-content h5 {
    margin: 1rem 0;
    font-size: 3rem;
    font-family: 'Maison Neue Bold';
}

.contact-back-content button {
    font-weight: 800;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    
}
.contact-back-content button img {
    margin-right: 1rem;
}
.pix-div {
    display: flex;
    align-items: center;
    
}

.pix-qr-code {
    height: 100%;
    width: 140px;
}
.pix-div h6 {
    font-size: 1.5rem;
}

.pix-div span {
    cursor: pointer;
}

.pix-div div {
    display: flex;
    flex-direction: column;
}

@media (max-width: 950px) {
    .contact { 
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        max-width: 100%;
        padding-bottom: 40px;
        margin: 3rem 0;
    
    }

    .contact-info  {
        display: flex;
        width:100%;
        padding: 0;
        margin: 0;
        position: relative;
        justify-content: flex-end;
        overflow: hidden;
    }
    .info-contact-container {
        border-radius: 100px 0 0 100px;
    }
    .info-contact-container li{
        display: flex;
        list-style: none;
        padding: 1rem;
        align-items: flex-start;
        max-width: 100%;
    }

    .info-contact-container img {
        display: flex;
        width: 30px;
        height: 30px;
        max-width: 100%;
    }

    .info-contact-container p,
    .info-contact-container h6{
        max-width: 80%;
    }
    .info-contact-container p{
        font-size: 0.8rem;
    }
   
    .yellow-side-container{
        display: none;
        
    }
    .pix-div {
        flex-direction: column;
    }
    .pix-qr-code {
        height: 100%!important;
        width: 190px!important;
    } 
}