.metodology-main {
    display: block;
    background-color: black;
    max-width: 100%;
    border-radius: 0 200px 0 0;
    overflow: hidden;
}

.first-metodology-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Cria 3 colunas de tamanho igual */
    grid-gap: 40px; /* Define o espaçamento entre as colunas */
    padding: 5em;
    padding-bottom: 0;
}

.first-metodology-content div {
    margin: 80px 0;
    padding: 40px;
    box-sizing: border-box;


}
.first-metodology-content h2 {
    color: var(--yellow-color);
    font-size: 4.5em;
    max-width: 100%;
    font-family: 'Maison Neue Extra Bold';
    margin-bottom: 3rem;
}
.yellow-container {
    margin-top: 0;
    position: relative;
    box-sizing: border-box;
    background-color: rgb(221, 1, 34);

}
.yellow-bar {
    position: absolute;
   
    width: 90vw;

    bottom: 100%;
    left: 12%;
    background-color: var(--yellow-color);
    border-radius: 250px 0 0 250px;
    box-sizing: border-box;
}

.yellow-bar p {
    font-family: 'Maison Neue Bold';
    font-size: 2em!important;
    color: black!important;
    padding: 2.5em 5em;
    text-align: initial;
}

.first-metodology-content p {
    color: white;
    font-size: 1.2em;
    max-width: 100%;
    margin-bottom:1em;
}    
.first-metodology-content img {
    padding-top: 5rem;
    position:static;
 
    max-width: 100%;
}

.second-metodology-content {
    background-color: black;
    border-radius: 0 200px 0 0;
    display: relative;
    z-index: 1;
}

.border-yellow {
    width: 100vw;
    background-color: var(--yellow-color);
}
.second-metodology-content ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    padding: 80px 0 80px 20px;
    gap: 0px;
    margin: 0;
    max-width: 100%
    
    /* display: flex;
    flex-direction: row;
    padding: 40px; */
}

.sol {
    width: 367px;
    height: 323px;
    perspective: 1000px;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sol img {
    display: flex;

}


.store-button {
    display: inline-block;
    margin-top: 5em;
    padding: 15px 25px;
    background-color: #FFD700; /* Amarelo */
    color: black;
    text-decoration: none; /* Remove o sublinhado do link */
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Adiciona uma transição suave */
}

.store-button:hover {
    background-color: #e0c700; /* Amarelo um pouco mais escuro ao passar o mouse */
}


@media (max-width: 950px) {
    .metodology-main {
        border-radius: 0 80px 0 0;
    }
    .second-metodology-content {
        border-radius: 0 80px 0 0;
    }
    .first-metodology-content div {
        padding: 0;
    }
    .first-metodology-content p {
        display: inline-flex;
        justify-content: center;
        justify-items: center;
        text-align: justify;
        font-weight: 400;
        max-width: 100%;
        
    }

    .first-metodology-content h2 {
        font-size: 3em;
        max-width: 100%;
        
    }
    .second-metodology-content ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        grid-gap: 40px; 
        padding: 0px;
        margin: 1rem 0;
    }
    .second-metodology-content li {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 2rem 0;
        padding: 0px;
    } 

}

@media (max-width: 1324px) {
    .yellow-bar{
        display: none;
    }


    .first-metodology-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Cria 3 colunas de tamanho igual */
        grid-gap: 40px; /* Define o espaçamento entre as colunas */
        padding: 5em;
    }

     
    
}

@media (min-width:950px) and (max-width: 1324px) {

    .second-metodology-content ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 0px; 
        padding: 0px;
        margin: 0;
        max-width: 100%;
    }

    .second-metodology-content {
        border-radius: 0 0 80px 0;
    }
}