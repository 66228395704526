.dropdown {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
  }
  
  .dropdown-toggle {
    background-color: transparent;
    font-family: 'Maison Neue Bold';
    font-size: 18px;
    padding: 1rem 2.5rem 0 0;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 20;
   
  }
  
  .dropdown-menu {
    z-index: 100000;
    position: absolute;
    top: 100%;
    left: 0;
    background-color:#f5ececb6;
    transition: 2s linear;
    padding: 0px;
    z-index: 2;
    list-style: none;
    box-sizing: border-box;
  }
  
  .dropdown li {
    margin: 0em 0.5em;
    transition: 1s linear;
    padding: .5rem!important;
  } 

  .dropdown li:hover {
    transition: .5s linear;
  }
.link {
    font-weight: 200;
}
@media (max-width: 950px) {
  .dropdown-menu {
    background-color:transparent;
    transition: 2s linear;
    padding: 0px;
    margin-top: 2rem;
    color: white!important;
    margin-left: -3rem;
    display: flex;
    flex-direction: column;
    z-index: 2;
    list-style: none;
    box-sizing: border-box;
    overflow: scroll;
  }
  .subDropdown {
    display: flex;
    flex-direction: column;
    width: 13.5rem!important;
    gap: .7rem;
    margin-left: -4.5rem!important;
    align-items: right;
    padding: 1rem .2rem;
}
  }
.subDropdown {
    display: flex;
    flex-direction: column;
    width: 10.3rem;
    gap: .7rem;
    margin-left: -2rem;
    align-items: right;
    padding: 1rem .2rem;
}

@media (max-width: 950px) {
  .dropdown-toggle {
    padding: 0;
    color: white
  }
  .dropdown li {
    
    margin: 0;
    transition: 1s linear;
    padding: 0;
    background-color: transparent;

  }
}